.element {
  margin: 30px 0;

  .title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 3px;
    font-weight: 700;
    font-size: 21px;
  }

  .progressbar {
    background-color: #ddd;
    height: 15px;
  }
}

.outbreak {
  animation: blinking 2s linear infinite;
  padding: 30px 20px;
}

@keyframes blinking {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: #cc6e6e;
  }
  100% {
    background-color: transparent;
  }
}
