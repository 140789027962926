.switchDiv {
  margin: auto 0;

  .label {
    cursor: pointer;
    &:before {
      left: -1.75rem;
    }
    &:after {
      left: calc(-1.75rem + 2px);
    }
  }
}
