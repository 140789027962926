.App {
  font-family: Arial, Helvetica, sans-serif;
  color: #444;
  min-height: 100vh;
  background-image: url(https://source.unsplash.com/random);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;

  .main {
    padding: 50px 0;

    .box {
      max-width: 600px;
      margin: 10px auto;
      text-align: left;
      background-color: #eee;
      padding: 40px 70px;

      .titleDiv {
        display: flex;
        justify-content: space-between;

        .bigTitle {
          font-size: 38px;
          font-weight: bold;
        }
      }

      .bigDescription {
        font-size: 18px;
        font-weight: bold;
      }

      .footer {
        margin-top: 10px;
        font-size: 11px;
      }
    }
  }
}
